import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faSearch)
library.add(faPlus)
library.add(faMinus)

createApp(App)
	.use(router)
	.component('fa', FontAwesomeIcon)
	.mount('#app')
