import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // 破産者情報検索
  {
    path: '/',
    name: 'Hasan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Hasan.vue'),
  },
  // 通知設定
  {
    path: '/tripwire',
    name: 'Tripwire',
    component: () => import('../views/Tripwire.vue')
  },
  // ログイン
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  // 会員登録
  {
    path: '/verify',
    name: 'Verify',
    component: () => import('../views/Verify.vue')
  },
  // パスワード再設定
  {
    path: '/recovery',
    name: 'Recovery',
    component: () => import('../views/Recovery.vue')
  },
  // メール再設定
  {
    path: '/mail',
    name: 'Mail',
    component: () => import('../views/Mail.vue')
  },
  // ユーザー情報
  {
    path: '/userinfo',
    name: 'UserInfo',
    component: () => import('../views/UserInfo.vue')
  },
  // 課金設定
  {
    path: '/purchase',
    name: 'Purchase',
    component: () => import('../views/Purchase.vue')
  },
  // 会員管理・会計
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
