<template>
  <h1><a href="/">破産者情報通知サービス</a></h1>
  <div class="notice">現在、当局の命令によりサイトの機能が制限されています。<a href="https://info.hasannews.org/%e3%81%8a%e7%9f%a5%e3%82%89%e3%81%9b/">詳細はこちらで報告しております。</a></div>
  <router-view/>
  <footer>
<a href="https://info.hasannews.org/legal-notice/" target="_blank">特定商取引法に基づく表示</a> |
<a href="https://info.hasannews.org/ppc/" target="_blank">個人情報保護法に基づく表示</a> |
<a href="https://info.hasannews.org/privacy-policy/" target="_blank">プライバシーポリシー</a> |
<a href="https://info.hasannews.org/contact/" target="_blank">お問い合わせ</a> |
<a href="/?o=0">過去30日</a>
  </footer>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body {
	font: 10pt sans-serif;
  margin: 0;
  height: 100%;
}
#app {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 30pt;
  display: flex;
  flex-direction: column;
}
footer {
  position: absolute;
  width: 100%;
  height: 30pt;
  bottom: -30pt;
  background-color: #083F50;
  padding: 4pt;
  font-size: 10pt;
  line-height: 1.2;
  text-align: center;
}
footer,
footer a {
  color: #EEEDF1;
}

* {
	box-sizing: border-box;
}
p {
  margin-bottom: 1em;
}

a {
  text-decoration: none;
  color: #666666;
}
a:hover {
  text-decoration: underline;
}
strong {
  font-weight: bold;
  color: #FF0000;
}

div.loading {
  height: 30px;
  background: no-repeat center url(/loading.gif);
}

h1,
h1 a,
h1 a:hover {
  font-size: 20pt;
  padding: 4pt;
  color: #EEEDF1;
  background-color: #083F50;
  text-decoration: none;
}
h2 {
  font-size: 12pt;
  font-weight: bold;
  padding: 2pt;
  color: #144E94;
  text-align: center;
}

button {
  background-color: #2999C4;
  color: #FFFFFF;
  border: none;
  border-radius: 10pt;
  margin: 4pt;
}
button:disabled {
    background-color: #CCCCCC;
}

div.scroll {
  border-top: 4pt solid transparent;
  overflow: auto;
  flex-grow: 1;
  flex-basis: 0;
}

table.list {
	border-collapse: collapse;
  min-width: 100%;
  width: 100%;
}
table.list th,
table.list td {
  white-space: nowrap;
	padding: 2pt;
  border: 1pt solid #082F50;
}
table.list tbody tr:nth-child(odd) {
  background-color: #EEEDF1;
}
table.list tbody tr:nth-child(even) {
  background-color: #A5C5D6;
}
table.list th {
	font-weight: bold;
  color: #FFFFFF;
  background-color: #082F50;
}
div.notice {
  color: #F00;
  font-weight: bold;
}
div.notice a {
  color: #C00;
}
</style>
